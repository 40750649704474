import React, { useState } from "react";
import Flex from "app/components/Flex";
import { H3 } from "app/components/Typography";
import convertTimeToString from "helpers/convertTimeToString";
import convertTimeObjectToSeconds from "helpers/convertTimeObjectToSeconds";
import { useHistory, useLocation } from "react-router";
import { useContentSelectClassEvent } from "hooks/analytics";
import { getAnalyticsLocationFromPath } from "helpers/analytics";
import { Class } from "services/graphql";
import {
  ExplicitLabel,
  CompletedIcon,
  ThumbnailContainer,
  ThumbnailWrapper,
  ClassInfoText,
  LinkWrapper,
  ProgressBar,
} from "./styles";
import { ClassInfoLabel } from "./ClassInfoLabel";
import { ClassThumbnail } from "../ClassThumbnail";

interface Props {
  classData: Class;
}

export function ClassHeader({ classData }: Props) {
  const history = useHistory();
  const location = useLocation();
  const { contentSelectClassEvent } = useContentSelectClassEvent({
    classData,
  });

  const classUrl = `/class/${classData.id}`;

  const handleClick = (e: any) => {
    e.preventDefault(); // To ignore link tag href

    contentSelectClassEvent({
      location: getAnalyticsLocationFromPath(location.pathname),
      selected_from: "Class User Posts",
    });

    history.push(classUrl, {
      previousLocation: location,
    });
  };

  const getUserProgressPercent = () => {
    if (!classData.video) {
      return 0;
    }
    const durationInSeconds = Number(classData.video.durationInMs) / 1000;
    return (
      (convertTimeObjectToSeconds(classData.progress?.time) /
        durationInSeconds) *
      100
    );
  };

  return (
    <>
      <Flex width="100%" justifyContent="space-between" maxWidth="768px">
        <Flex gap="16px" flexWrap="wrap">
          <LinkWrapper onClick={handleClick} href={classUrl}>
            <ThumbnailContainer>
              <ThumbnailWrapper>
                <ClassThumbnail classData={classData} />
                {classData.isExplicit && <ExplicitLabel />}
                {classData.progress?.completed && <CompletedIcon />}
                {classData.progress?.percent && (
                  <ProgressBar percent={getUserProgressPercent()} />
                )}
              </ThumbnailWrapper>
            </ThumbnailContainer>
          </LinkWrapper>
          <Flex flexDirection="column">
            <LinkWrapper onClick={handleClick} href={classUrl}>
              <H3 mb="12px" limitLines={2}>
                {classData.title}
              </H3>
            </LinkWrapper>
            <Flex gap="8px">
              {classData.level && <ClassInfoLabel label={classData.level} />}
              {classData.video && (
                <ClassInfoLabel
                  label={convertTimeToString(
                    Number(classData.video.durationInMs) / 1000,
                    { discrete: true, showSeconds: false }
                  )}
                />
              )}
              <ClassInfoLabel label={classData.type} />
            </Flex>
            <Flex gap="8px" alignItems="center">
              <ClassInfoText>{classData.style}</ClassInfoText>
              <div>{" • "}</div>
              <ClassInfoText>{classData.instructor?.name}</ClassInfoText>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}
