import Flex from "app/components/Flex";
import React from "react";
import { useHistory, useParams } from "react-router";
import { useGetClassAndUserPostsByIdQuery } from "services/graphql";
import { ANALYTICS_MODULE } from "constants/analytics";
import { UploadUserVideoModal } from "app/components/UploadUserVideoModal";
import Breadcrumbs from "app/components/Breadcrumbs";
import { CenterWrapper, Container } from "app/components/RouteComponents";
import { Helmet } from "react-helmet-async";
import { H2 } from "app/components/Typography";
import { UserPostList } from "./UserPostList";
import { ClassHeader } from "./ClassHeader";

export default function UserPosts() {
  const { classNumber } = useParams<{ classNumber: string }>();
  const history = useHistory();

  const { data, loading, error } = useGetClassAndUserPostsByIdQuery({
    variables: {
      classId: classNumber,
    },
  });

  if (loading) {
    return null;
  }

  if (error) {
    return null;
  }

  if (!data) {
    return null;
  }

  const { classById: classData } = data;

  return (
    <>
      <Helmet
        title={`Submissions for ${classData.title} | Online Dance Classes and Tutorials`}
      />
      <CenterWrapper>
        <Container>
          <Breadcrumbs
            onClick={() => {
              history.push(`/class/${classNumber}`);
            }}
          >
            &lt; Back to Class
          </Breadcrumbs>
          <Flex
            justifyContent="space-between"
            width="100%"
            mb="64px"
            flexWrap="wrap"
            gap="16px"
          >
            <ClassHeader classData={classData} />
            <UploadUserVideoModal
              classNumber={Number(classNumber)}
              buttonProps={{ width: "150px" }}
            />
          </Flex>
          <H2 mb="8px">Submissions</H2>
          <Flex>
            <UserPostList
              videos={classData.userVideos.edges}
              fromModule={ANALYTICS_MODULE.class_details}
            />
          </Flex>
        </Container>
      </CenterWrapper>
    </>
  );
}
